// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
import * as Turbo from '@hotwired/turbo'
Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

import '@fortawesome/fontawesome-free/js/all.min'
import '../src/main'
import '../src/scrolltop'
import '../src/cookie'

// Example: Import a stylesheet in app/frontend/index.css
import '~/application.css'

import '../../assets/stylesheets/bootstrap.scss'

// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

document.addEventListener('turbo:load', () => {
  fbAsyncInit()

  // var counter = 0;
  // var i = setInterval(function(){
  //   console.clear()
  //   counter++;
  //   if(counter === 10) {
  //     clearInterval(i);
  //   }
  // }, 500);

  $('.js-readmore').readmore({
    speed: 100,
    moreLink: '<a href="#" class="moreLink">Xem thêm</a>',
    lessLink: '<a href="#" class="lessLink">Ẩn</a>',
    collapsedHeight: 200,
  })
})
